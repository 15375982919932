import React from 'react'
import { Stack, Hero, Main, Sidebar, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Sticky from '@components/Sticky'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import Tags from '@widgets/Tags'
import NewsletterCompact from '@widgets/NewsletterCompact'
import Social from '@widgets/Social'
import HeroComponent from '../components/Hero/Hero'
import Testimonial from '@widgets/Testimonial'

const Posts = ({ data: { paginatedPosts = {} }, ...props }) => {
  const { pageContext: { services = {} } = {} } = props
// console.log(paginatedPosts.nodes[0])
  return (
    <>
      <Seo title='Home' />
      <Hero sx={{ bg: `contentBg` }}>
        <HeroComponent {...props} />
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <CardList
            variant={['horizontal-md', 'horizontal']}
            title='Recently Published'
            nodes={paginatedPosts.nodes}
            columns={[1]}
          />
        </Main>
        <Sidebar>
          <Categories />
          <Divider />
          <Tags />
          <Divider />
          <Social />
          <Sticky>
            {services.mailchimp && (
              <>
                <Divider />
                <NewsletterCompact />
              </>
            )}
          </Sticky>
        </Sidebar>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...paginatedPosts.pageInfo} />
      </PreFooter>
      <Divider />
      <Stack>
        <Main>
        <CardList
            nodes={paginatedPosts.nodes}
            variant='vertical-cover'
            limit={4}
            columns={[1, 2]}
            title='Testimonials'
            slider
            autoPlay
          />
          <Testimonial />
          
        </Main>
        </Stack >
      <Divider />
    </>
  )
}

export default Posts
